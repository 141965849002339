import React, {useState, useMemo, useRef} from 'react';
import MyosLogo from './logo-white.svg';
import {Range} from 'react-range';
import NumberFormat from 'react-number-format';
import {CUMIPMT} from './formulas';
import config from 'config';

const minLoanVolume = config.minLoanVolume ?? 10_000;
const maxLoanVolume = config.maxLoanVolume ?? 2_500_000;
const signupLink = `https://app.myos.com/signup?lng=${__('locale')}&utm_source=websiteCalculator`;

function currency(num, options = {}) {
    return num.toLocaleString(__('locale'), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ...options,
    });
}

export function Widget() {
    const [loanVolume, setLoanVolume] = useState(config.startLoanVolume ?? 70_000);
    const [duration, setDuration] = useState(config.startDuration ?? 3);

    const interestPerMonth = useMemo(
        () => -CUMIPMT(config.startInterest / 100, duration, loanVolume, 1, duration, 0),
        [loanVolume, duration],
    );

    const monthlyFee = (interestPerMonth / loanVolume) * 100;

    const inputRef = useRef(null);

    return (
        <>
            <div className="mcw-calculator">
                <h2 className="mcw-calculator-title">{__('interestCalculator')}</h2>
                <div className="mcw-calculator-loan">
                    <div className="mcw-calculator-loan-label">{__('loanVolume')}</div>
                    <NumberFormat
                        value={loanVolume}
                        className="mcw-loan-input"
                        decimalScale={0}
                        thousandSeparator={true}
                        prefix={
                            config.countryCode === 'gb' || config.countryCode === 'us'
                                ? __('currencySymbol')
                                : undefined
                        }
                        suffix={
                            config.countryCode !== 'gb' && config.countryCode !== 'us'
                                ? __('currencySymbol')
                                : undefined
                        }
                        getInputRef={inputRef}
                        renderText={(value, props) => <div {...props}>{value}</div>}
                        allowNegative={false}
                        allowLeadingZeros
                        isAllowed={({floatValue}) => floatValue <= maxLoanVolume}
                        onValueChange={({floatValue}) => {
                            setLoanVolume(floatValue);
                        }}
                        onFocus={() => {
                            inputRef.current.select();
                        }}
                    />
                    <div className="mcw-calculator-slider-labels mb-3">
                        <span>{__('min10k')}</span>
                        <span>{__('max1M')}</span>
                    </div>
                </div>

                <div className="mcw-calculator-slider mb-3">
                    <div className="mcw-calculator-label">{__('duration')}</div>
                    <div className="mcw-calculator-label-description">
                        {__('durationDescription1')}
                    </div>
                    <div className="mcw-calculator-label-description mb-2">
                        {__('durationDescription2')}
                    </div>
                    <div className="mcw-calculator-output mb-2">
                        {duration} {duration !== 1 ? __('months') : __('month')}
                    </div>
                    <Range
                        step={1}
                        min={1}
                        max={config.maxDuration ?? 9}
                        values={[duration]}
                        onChange={values => setDuration(values[0])}
                        renderTrack={({props, children}) => (
                            <div
                                {...props}
                                style={{
                                    ...props.style,
                                    height: '1px',
                                    backgroundColor: '#000',
                                }}
                            >
                                {children}
                            </div>
                        )}
                        renderThumb={({props}) => (
                            <div
                                {...props}
                                style={{
                                    ...props.style,
                                    height: '18px',
                                    width: '18px',
                                    borderRadius: '18px',
                                    backgroundColor: '#76A9FF',
                                    boxShadow: '0px 50px 100px rgba(0, 0, 0, 0.3)',
                                }}
                            />
                        )}
                    />
                    <div className="mcw-calculator-slider-labels">
                        <span>{__('minM')}</span>
                        <span>{__('maxM')}</span>
                    </div>
                </div>
                <div>
                    <div className="mcw-calculator-label">{__('monthlyFeeOnCredit')}</div>

                    <div className="mcw-calculator-label-description">
                        {__('rateForAverageProject')}
                    </div>
                    <div className="mcw-calculator-output">{currency(config.startInterest)} %</div>
                </div>
            </div>

            <div className="mcw-result">
                <div className="mcw-result-logos mb-4">
                    <img src={MyosLogo} alt="Myos" />
                </div>
                <div className="mcw-result-myos">
                    <div className="mb-3">
                        <div className="mcw-result-label">{__('totalCost')}</div>
                        <div className="mcw-result-result">
                            {loanVolume >= minLoanVolume
                                ? interestPerMonth.toLocaleString(config.countryCode, {
                                      style: 'currency',
                                      currency: config.currency,
                                      currencyDisplay: 'narrowSymbol',
                                  })
                                : '--'}
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="mcw-result-label">{__('correspondTo')}</div>
                        <div className="mcw-result-result">
                            {loanVolume >= minLoanVolume ? `${currency(monthlyFee)} %` : '--'}
                        </div>
                        <div className="mcw-result-label-desc">{__('totalCreditVolume')}</div>
                    </div>

                    <div>
                        <a className="mcw-button mb-4" href={signupLink}>
                            {__('requestOffer')}
                        </a>
                        <div className="mcw-disclaimer">{__('disclaimer')}</div>
                    </div>
                </div>
            </div>
        </>
    );
}
