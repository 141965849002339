exports.CUMIPMT = function CUMIPMT(rate, periods, value, start, end, type) {
    // Return error if either rate, periods, or value are lower than or equal to zero
    if (rate <= 0 || periods <= 0 || value <= 0) {
        return NaN;
    }

    // Return error if start < 1, end < 1, or start > end
    if (start < 1 || end < 1 || start > end) {
        return NaN;
    }

    // Return error if type is neither 0 nor 1
    if (type !== 0 && type !== 1) {
        return NaN;
    }

    // Compute cumulative interest
    var payment = exports.PMT(rate, periods, value, 0, type);
    var interest = 0;

    if (start === 1) {
        if (type === 0) {
            interest = -value;
            start++;
        }
    }

    for (var i = start; i <= end; i++) {
        if (type === 1) {
            interest += exports.FV(rate, i - 2, payment, value, 1) - payment;
        } else {
            interest += exports.FV(rate, i - 1, payment, value, 0);
        }
    }
    interest *= rate;

    // Return cumulative interest
    return interest;
};

exports.FV = function FV(rate, periods, payment, value, type) {
    // Credits: algorithm inspired by Apache OpenOffice

    value = value || 0;
    type = type || 0;

    // Return future value
    var result;
    if (rate === 0) {
        result = value + payment * periods;
    } else {
        var term = Math.pow(1 + rate, periods);
        if (type === 1) {
            result = value * term + (payment * (1 + rate) * (term - 1)) / rate;
        } else {
            result = value * term + (payment * (term - 1)) / rate;
        }
    }
    return -result;
};

exports.PMT = function PMT(rate, periods, present, future, type) {
    // Credits: algorithm inspired by Apache OpenOffice

    future = future || 0;
    type = type || 0;

    // Return payment
    var result;
    if (rate === 0) {
        result = (present + future) / periods;
    } else {
        var term = Math.pow(1 + rate, periods);
        if (type === 1) {
            result =
                ((future * rate) / (term - 1) + (present * rate) / (1 - 1 / term)) / (1 + rate);
        } else {
            result = (future * rate) / (term - 1) + (present * rate) / (1 - 1 / term);
        }
    }
    return -result;
};

exports.RATE = function RATE(periods, payment, present, future, type, guess) {
    guess = guess === undefined ? 0.01 : guess;
    future = future === undefined ? 0 : future;
    type = type === undefined ? 0 : type;

    // Set maximum epsilon for end of iteration
    var epsMax = 1e-10;

    // Set maximum number of iterations
    var iterMax = 10;

    // Implement Newton's method
    var y,
        y0,
        y1,
        x0,
        x1 = 0,
        f = 0,
        i = 0;
    var rate = guess;
    if (Math.abs(rate) < epsMax) {
        y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
    } else {
        f = Math.exp(periods * Math.log(1 + rate));
        y = present * f + payment * (1 / rate + type) * (f - 1) + future;
    }
    y0 = present + payment * periods + future;
    y1 = present * f + payment * (1 / rate + type) * (f - 1) + future;
    i = x0 = 0;
    x1 = rate;
    while (Math.abs(y0 - y1) > epsMax && i < iterMax) {
        rate = (y1 * x0 - y0 * x1) / (y1 - y0);
        x0 = x1;
        x1 = rate;
        if (Math.abs(rate) < epsMax) {
            y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
        } else {
            f = Math.exp(periods * Math.log(1 + rate));
            y = present * f + payment * (1 / rate + type) * (f - 1) + future;
        }
        y0 = y1;
        y1 = y;
        ++i;
    }
    return rate;
};
