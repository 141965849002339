import React from 'react';
import ReactDOM from 'react-dom';
import './Widget.css';
import {Widget} from './Widget.jsx';

export function init(parentEl) {
    const widgetContainer = document.createElement('div');
    widgetContainer.id = 'myos-calculator-widget';
    (parentEl ?? document.currentScript.parentElement).appendChild(widgetContainer);

    ReactDOM.render(
        <React.StrictMode>
            <Widget />
        </React.StrictMode>,
        widgetContainer,
    );
}
